.exercise__top {
	margin-bottom: 50px;
	text-align: center;
}

.exercise__top h2 {
	font-size: 2.4rem;
	margin-bottom: 1rem;
}

.exercise__top p {
	color: var(--heading-color);
	font-size: 1rem;
	line-height: 28px;
}

.exercise__wrapper {
	background: var(--bg-gradient);
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 80px 60px;
}

.exercise__item {
	display: flex;
	align-items: center;
	column-gap: 1.2rem;
	width: 33%;
}

.exercise__icon {
	width: 20%;
	height: 20%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	border-radius: 5px;
	background: #fff;
}

.exercise__icon img {
	width: 100%;
}

.exercise__content {
	width: 80%;
}

.exercise__content h4 {
	font-size: 1.3rem;
	color: #fff;
	font-weight: 500;
	margin-bottom: 0.6rem;
}

.exercise__content p {
	font-size: 1rem;
	color: rgba(255, 255, 255, 0.801);
}
