.hero__wrapper {
	display: flex;
	justify-content: space-between;
	padding-top: 50px;
}

.hero__content .section__title {
	font-size: 3rem;
	padding-right: 60px;
	line-height: 60px;
	margin-bottom: 20px;
}

.highlights {
	color: var(--primary-color);
}

.hero__content p {
	font-size: 1.1rem;
	color: var(--heading-color);
	margin-bottom: 50px;
	line-height: 30px;
}

.hero__btns {
	display: flex;
	align-items: center;
	column-gap: 3rem;
}

.watch__btn {
	padding: 5px;
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	column-gap: 1rem;
	color: var(--heading-color);
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
	border-radius: 5px;
}

.watch__btn span {
	background: #fff;
	border-radius: 50%;
	padding: 10px;
	color: var(--primary-color);
	font-size: 1.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 3px 3px 8px -1px #d3cef2;
}

.box-01 {
	width: 400px;
	height: 400px;
	border-radius: 100%;
	border: 25px solid rgba(141, 141, 235, 1);
}

.box-02 {
	width: 350px;
	height: 350px;
	border-radius: 100%;
	border: 25px solid #b5a9f3;
}

.box-03 {
	width: 300px;
	height: 300px;
	border-radius: 100%;
	border: 25px solid #d3cef2;
}

.hero__img-wrapper {
	position: relative;
	z-index: 1;
}

.box__img {
	position: absolute;
	top: -12%;
	left: -13%;
	z-index: 10;
}

.box__img img {
	width: 100%;
}

.heart__rate {
	width: 100px;
	position: absolute;
	top: 55%;
	left: -11%;
	z-index: 10;
	box-shadow: -3px 3px 8px -1px #d3cef2;
	text-align: center;
	background: #fff;
	border-radius: 5px;
	padding: 15px 10px;
}

.heart__rate span {
	width: 30px;
	height: 25px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #d3cef2;
	color: #d7337a;
	border-radius: 3px;
	padding: 3px;
}

.heart__rate h5,
h6 {
	font-size: 0.8rem;
	color: var(--heading-color);
	font-weight: 600;
}

.heart__rate h5 {
	margin-bottom: 10px;
}

.heart__rate h6 {
	margin-top: 10px;
}

.fitness__location {
	position: absolute;
	top: -2%;
	right: -17%;
	z-index: 10;
	box-shadow: 3px -3px 8px -1px #d3cef2;
	text-align: center;
	background: #fff;
	border-radius: 5px;
	padding: 1.2rem;
}

.fitness__location span {
	width: 50px;
	height: 50px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	background: var(--primary-color);
	color: #fff;
	font-size: 1.5rem;
	border-radius: 50%;
}

.fitness__location h5 {
	color: var(--heading-color);
	margin-top: 1rem;
	font-size: 0.9rem;
}

.dumble__icon {
	position: absolute;
	top: -2%;
	left: -5%;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	background: #d7337a;
	color: #fff;
	border-radius: 5px;
	padding: 15px 10px;
}

.dumble__icon img {
	width: 100%;
}
