@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap');

:root {
  --primary-color: #6f55f2;
  --heading-color: #132742;
  --bg-gradient: linear-gradient(90deg, rgba(103, 101, 240, 1) 30%, rgba(141, 141, 235, 1) 85%);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3 {
  font-weight: 700;
}

section {
  padding: 50px 0px;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  list-style: none;
}


.container {
  width: 1260px;
  padding: 0px 10px;
  margin: auto;
}

.section__title {
  color: var(--heading-color);
  font-weight: 800;
}
