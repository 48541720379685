.pricing__top {
	text-align: center;
}

.pricing__top h2 {
	font-size: 2.5rem;
	margin-bottom: 20px;
}

.pricing__top p {
	font-size: 1.1rem;
	color: var(--heading-color);
	line-height: 28px;
	margin-bottom: 50px;
}

.pricing__wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 2rem;
}

.pricing__item {
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	border-radius: 5px;
	width: 33%;
	background: #fff;
}

.pricing__card-top {
	padding: 30px;
	border-bottom: 1px solid #d3cef2;
	text-align: center;
}

.pricing__card-top h2 {
	font-size: 1.5rem;
	margin-bottom: 0.9rem;
}

.pricing__card-top .pricing {
	font-size: 1.4rem;
}

.pricing__card-top .pricing span {
	font-size: 1rem;
	color: #666;
}

.pricing__item .services {
	padding: 30px;
}

.pricing__item .services ul li {
	display: flex;
	align-items: center;
	column-gap: 1.5rem;
	color: #666;
	line-height: 28px;
}

.pricing__item .services ul li span i {
	font-size: 0.7rem;
}

.services {
	text-align: center;
}

.services button {
	margin-top: 50px;
}

.pricing__item-02 .pricing__card-top {
	background: var(--primary-color);

	border-radius: 5px 5px 0px 0px;
}

.pricing__item-02 .pricing__card-top h2 {
	color: #fff;
	font-weight: 500;
}

.pricing__item-02 .pricing__card-top h2 span {
	color: rgba(255, 255, 255, 0.788);
}

.pricing__item-02 .services ul li span i {
	color: var(--primary-color);
}
